body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  vertical-align: middle;
}

.alert{
  display: flex;
  gap: 1em;
  padding: 1em;
  margin: 1em 0;
  border-radius: .25em;
}
.alert article{
  flex: 1;
  font-size: 90%;
}
.alert article *:first-child{
  margin-top: 0;
}
.alert article *:last-child{
  margin-bottom: 0;
}
.alert-warning{
  background-color: rgb(255, 244, 229);
  color: rgb(102, 60, 0);
}

.alert-warning aside{
  color: rgb(237, 108, 2);
}

.main-menu .MuiAccordion-root.Mui-expanded{
  margin: 0;
}
.main-menu .MuiAccordionDetails-root{
  padding: 0;
}
.main-menu .MuiAccordionSummary-root.Mui-expanded{
  min-height: 48px;
}
.main-menu .MuiAccordionSummary-content.Mui-expanded{
  margin: 12px 0;
}
.main-menu .sub-menu{
  margin: 0;
}

.store-product-show .attributes-table th{
  font-weight: bold;
  color: #999;
}
.store-product-show .attributes-table .column-attributeName{
  width: 12em;
}
.store-product-show .attributes-table .column-attributeOptions .column-additionalPrice{
  width: 8em;
}
.store-product-show .attributes-table .column-attributeOptions .column-enabled{
  width: 4em;
}
.store-product-show .attributes-table .column-attributeOptions .column-defaultOption{
  width: 8em;
}
.store-product-edit .attributes-form .MuiButton-root{
  line-height: 100%;
}

.product-program-key-relations-filter-configurations .MuiFormHelperText-root > span{
  display: none;
}

.refresh-interval-form{
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
}

@media (min-width: 0px) and (max-width: 599.95px){
  .MuiScopedCssBaseline-root{
    height: 100%;
    overflow: auto;
  }
  .main-menu .MuiPaper-root.MuiAccordion-root{
    height: auto;
  }
}