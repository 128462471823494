.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiToolbar-root.aside{
  padding: 0;
}
.MuiToolbar-root.aside .MuiBox-root{
  margin: 0 .5em 0 0;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular{
  z-index: 101;
}

.code-input textarea{
  font-family: monospace;
  white-space: pre;
  overflow: auto !important;
}

.bounding-boxes-container{
  position: relative;
}
.bounding-boxes-container .detected-box{
  position: absolute;
  border: 1px solid green;
}
.bounding-boxes-container .detected-box:hover{
  background-color: rgba(0, 128, 0, 0.2);
}

.bounding-boxes-container .expected-box{
  position: absolute;
  border: 1px solid red;
}

.state-machine-edge-label {
  position: absolute;
  background: white;
  font-size: 12px;
  max-width: 20em;
}
.state-machine-edge-label dl{
  display: flex;
  margin: 0;
}
.state-machine-edge-label dl dt{
  color: gray;
}
.state-machine-edge-label dl dd{
  margin: 0;
  color: green;
  font-weight: bold;
}
.state-machine-edge-label dl dt:after{
  content: ':';
  margin-right: .5em;
}

.state-machine-edge-label .group-key{
  word-break: break-all;
  font-size: 80%;
}

.state-machine-edge-label .no-group-key{
  color: #999;
  display: flex;
  align-items: center;
  gap: .5em;
}

.state-machine-edge-label .no-group-key .MuiSvgIcon-root{
  font-size: 1em;
}